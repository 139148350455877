body {
  margin: 0px;
  padding: 0px;

}

body {
  font-family: 'Stolzl';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl-Light.eot');
  src: url('../fonts/Stolzl-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Stolzl-Light.woff2') format('woff2'),
      url('../fonts/Stolzl-Light.woff') format('woff'),
      url('../fonts/STOLZL-LIGHT.TTF') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl-Medium.eot');
  src: url('../fonts/Stolzl-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Stolzl-Medium.woff2') format('woff2'),
      url('../fonts/Stolzl-Medium.woff') format('woff'),
      url('../fonts/STOLZL-MEDIUM.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl Book';
  src: url('../fonts/Stolzl-Book.eot');
  src: url('../fonts/Stolzl-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Stolzl-Book.woff2') format('woff2'),
      url('../fonts/Stolzl-Book.woff') format('woff'),
      url('../fonts/STOLZL-BOOK.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl-Regular.eot');
  src: url('../fonts/Stolzl-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Stolzl-Regular.woff2') format('woff2'),
      url('../fonts/Stolzl-Regular.woff') format('woff'),
      url('../fonts/STOLZL-REGULAR.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl-Thin.eot');
  src: url('../fonts/Stolzl-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Stolzl-Thin.woff2') format('woff2'),
      url('../fonts/Stolzl-Thin.woff') format('woff'),
      url('../fonts/STOLZL-THIN.TTF') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl-Bold.eot');
  src: url('../fonts/Stolzl-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Stolzl-Bold.woff2') format('woff2'),
      url('../fonts/Stolzl-Bold.woff') format('woff'),
      url('../fonts/STOLZL-BOLD.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


.headeractivenav {
  color: #548AFF !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.header-nav {
  color:
      #273266 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.logo img {
  width: 60%;
}

.headernavitems {
  margin: 0px 0px 0px 30px;
}

.newcontactbtn {
  border: 1px solid #273266;
  color: #273266 !important;
  font-size: 14px;
  font-weight: 400;
}

.newcontactbtn:hover {
  background: transparent !important;
  border: 1px solid #273266;
  color: #273266 !important;
}

.newcontactbtn:focus {
  box-shadow: none !important;
}

.header {
  box-shadow: 0px 0px 1px 0px #707070;
  padding: 20px 0px;
}

.banner {
  background-image: url('../images/bannerimg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
}

.banner-content h1 {
  color: #273266;
  font-size: 34px;
  font-weight: 400;
  margin: 0px;
  line-height: 50px;
}

.banner-content h1 span {
  color: #5185F6;
}

.banner-content p {
  margin: 0px;
  color: #273266;
  opacity: 0.50;
  font-size: 15px;
  font-weight: 400;
  padding: 20px 0px 30px 0px;
}

.newlearenmorebtn {
  background-color: #548AFF !important;
  color: #FFFFFF !important;
  font-size: 13px;
  font-weight: 500;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
}

.newlearenmorebtn:focus {
  color: #fff;
  background-color: #548AFF !important;
  border-color: inherit !important;
  box-shadow: none !important;
}

.abouts-usboxcontet p {
  color: #273266;
  font-size: 16px;
  opacity: 0.53;
  font-weight: 500;
  margin: 0px;
  line-height: 30px;
}

.abouts-usboxcontet {
  background-color: #FFFFFF;
  border-radius: 22px;
  box-shadow: -1px 3px 3px 3px #e2ebff;
  padding: 20px;
  display: flex;
  align-items: center;
}

.abouts-usboxcontet img {
  width: 10%;
  margin-right: 15px;
}

.newaboutboxbusiness {
  align-items: flex-start !important;
}

.newaboutboxbusiness p {
  opacity: inherit !important;
  color: #273266 !important;
  font-size: 15px;
}

.newaboutboxbusiness {
  margin-top: 35px;
}

.aboutcontentright {
  color: #548AFF;
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}

.aboutbusinesopurtonity {
  color: #273266;
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
  padding-top: 15px;
  line-height: 35px;
}

.abourparagraphbeatea {
  color: #000000;
  font-size: 15px;
  margin: 0px;
  opacity: 0.50;
  font-weight: 400;
  padding-top: 15px;
}

.abouts-us {
  padding: 100px 0px;
}

.newrightboxaboutus {
  display: flex;
  justify-content: center;
}

.aboutlineus {
  position: absolute;
  left: 89px;
  top: 0px;
}

.newunique {
  position: relative;
}

.srevices-content {
  text-align: center;
  padding-bottom: 25px;
}

.srevices-content h2 {
  color: #548AFF;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.srevices-content p {
  font-size: 17px;
  color: #273266;
  margin: 0px;
  font-weight: 400;
  padding-top: 10px;
  line-height: 35px;
}

.serviceboxcontent img {
  width: 50px;
  margin: 0px auto;
  height: 50px;
  object-fit: contain;
}

.serviceboxcontent h4 {
  font-size: 15px;
  color: #273266;
  font-weight: 500;
  margin: 0px;
  padding: 15px 0px;
}

.serviceboxcontent p {
  font-size: 13px;
  color: #000000;
  font-weight: 400;
  opacity: 0.50;
  margin: 0px;
}

.serviceboxcontent {
  background: #FFFFFF;
  border-radius: 25px;
  text-align: center;
  padding: 30px;
}

.services {
  background-image: url('../images/backimg.png');
  background-position: center;
  background-size: cover;
  height: 600px;
  padding-top: 100px;
}

.mainslidercompany .slick-slide {
  padding: 12px;
}

.partnetcontent h2 {
  color: #273266;
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
  text-align: center;
}

.partnetcontent h2 span {
  color: #548AFF;
}

.assocaitepartner {
  padding: 40px 0px;
}

.parnerboxcontent h4 {
  color: #273266;
  font-size: 18px;
  margin: 0px;
  font-weight: 400;
  padding-bottom: 10px;
}

.parnerboxcontent p {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  opacity: 0.50;
  margin: 0px;
  line-height: 25px;
}

.parnetrimages img {
  width: 80%;
}

.parnetrimages {
  margin: 0px auto;
  display: flex;
  justify-content: center;
}

.partnetcontent {
  padding-bottom: 40px;
}

.newpartneroffring {
  background-color: #FDEFF6;
  border-radius: 20px;
  padding: 50px 0px;
}

.assocaitepartner .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff !important;
  background-color: #548AFF;
  opacity: 1 !important;
  border-radius: 30px;
}

.assocaitepartner .nav-link {
  display: block;
  padding: .5rem 1rem;
  color: #548AFF !important;
  opacity: 0.40 !important;
  font-weight: 500;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.assocaitepartner .navitempartener {
  border: 1px solid #548aff6b;
  border-radius: 30px;
  margin: 0px 0px 0px 25px;

}

.assocaitepartner .nav {
  justify-content: center;
  margin-bottom: 40px;
}

.analyticscontent h2 {
  color: #548AFF;
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
}

.analyticscontent p {
  font-size: 16px;
  color: #273266;
  margin: 0px;
  font-weight: 400;
  padding-top: 10px;
}

.analyticsboxcontent {
  text-align: center;
}

.analyticsboxcontent h3 {
  color: #273266;
  font-size: 30px;
  font-weight: 400;
  margin: 0px;
}

.analyticsboxcontent p {
  color: #273266;
  font-size: 13px;
  opacity: 0.62;
  font-weight: 400;
  margin: 0px;
  padding-top: 9px;
}

.analyticsboxcontent img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.associate-banks{
width: 84px !important;
}
.analyticscontent {
  padding-bottom: 40px;
}

.analytics {
  padding: 40px 0px;
}



.mainslidercompany .slick-dots {
  bottom: -68px;
}

.mainslidercompany a.dot {
  padding: 0;
  font-weight: 500;
  font-size: 13px;
  color: #2732667a;
  text-decoration: none;
}

.mainslidercompany a.dot:hover {

  background: transparent;
}

.mainslidercompany .slick-dots li {
  border: 1px solid #2732667a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}


.mainslidercompany .slick-dots li.slick-active {
  background-color: #273266;
  border: 1px solid #273266;
  color: white;
}

.mainslidercompany .slick-dots li.slick-active a.dot {
  color: white;
}

.newawarderadd {
  background-color: #548AFF;
  border-radius: 30px;
}

.awarded {
  padding-top: 50px;
  padding-bottom: 90px;
}

.newawardedadd {
  background-image: url('../images/achivement.png');
  background-size: cover;
  height: 600px;
  position: relative;
  overflow: hidden;
  border-radius: 40px;
}

.awardebytittle {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  
}

.awardebox h5 {
  color: #FFFFFF;
  font-size: 16px;
  margin: 0px;
  padding: 10px 0px;
  font-weight: 500;
}

.awardebox p {
  color: #FFFFFF;
  font-size: 16px;
  margin: 0px;
  opacity: 0.59;
  font-weight: 500;
}

.awardebox img {
  margin: 0px auto;
  width: 85%;
}

.awardebox {
  text-align: center;
}

.mainsliderawared.slick-initialized .slick-slide {
  display: block;
  padding-top: 98px;
}

.eldridgeerdman h5 {
  color: #2A2A2A;
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
}

.eldridgeerdman p {
  margin: 0px;
  color: #2A2A2A;
  font-size: 12px;
  opacity: 0.50;
  font-weight: 500;
  padding-top: 4px;
}

.eldridgeimages {
  width: 20%;
  margin-right: 15px;
}

.testimonailboxcontent {
  background: #FFFFFF;
  padding: 30px;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  width: 82%;
  margin: 0px auto;
  position: relative;
  border-bottom: 4px solid #273266;
  margin-top: 25px;
}

.testimonialkc {
  background-color: #EFF4FF;
  padding: 85px 0px;
}

.magnamtesti {
  margin: 0px;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 15px;
  line-height: 23px;
}

.testiquoteimages {
  position: absolute;
  top: -12px;
  left: 35px;
  width: 11%;
}

.eldridgeerdman {
  text-align: initial;
}

.mainslidertesti .slick-dots li {
  border: 1px solid #2732667a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.mainslidertesti a.dot {
  padding: 0;
  font-weight: 500;
  font-size: 13px;
  color: #2732667a;
  text-decoration: none;
}

.mainslidertesti .slick-dots li.slick-active {
  background-color: #273266;
  border: 1px solid #273266;
  color: white;
}

.mainslidertesti .slick-dots li.slick-active a.dot {
  color: white;
}

.mainslidertesti .slick-dots {
  position: absolute;
  bottom: -60px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.newsubmitbtn {
  background-color: #548AFF !important;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  font-weight: 400;
  border-color: inherit !important;
  padding: 10px 30px;
  position: absolute;
  top: 9px;
  right: 11px;
}

.newsubmitbtn:focus {
  color: #fff;
  background-color: #5185F6 !important;
  border-color: #5185F6 !important;
  box-shadow: none !important;
}

.getintouchinput input {
  border: 1px solid #548aff66;
  border-radius: 5px;
  width: 85%;
  height: 60px;
  padding-left: 20px;
}

.getintouchinput input::placeholder {
  color: #273266;
  opacity: 0.53;
  font-size: 15px;
  font-weight: 400;
}

.getintouchinput input:focus-visible {
  outline: none !important;
}

.getintouchinput {
  position: relative;
  display: flex;
  justify-content: end;
}

.getintouchcontent h2 {
  color: #273266;
  font-size: 30px;
  font-weight: 600;
  margin: 0px;
}

.getintouchcontent p {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  padding-top: 2px;
}

.getinleftimages {
  position: absolute;
  left: 0px;
  top: 0px;
}

.getinleftimages img {
  width: 54%;
}

.getintouchcontent {
  position: relative;
  padding-left: 15px;
}

.getintouch {
  padding: 100px 0px;
  background-color: #EFF4FF;
}

.footer {
  background-color: #273266;
  padding: 60px 0px;
}

.footercontentcompany {
  color: #FFFFFF;
  font-size: 16px;
  margin: 0px;
  font-weight: 600;
  padding-bottom: 15px;
}

.footernavcontentadd {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.footernavcontentadd li a {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 400;
  opacity: 0.83;
  text-decoration: none;
}

.footernavcontentadd li {
  padding-bottom: 15px;
}

.mapmarker {
  padding-right: 10px;
  color: #FFFFFF;
  font-size: 14px;
}

.footerlogo {
  width: 50%;
  padding-bottom: 35px;
}

.footerdolore {
  margin: 0px;
  color: #FFFFFF;
  opacity: 0.63;
  font-size: 13px;
  font-weight: 400;
}

.footericon i {
  background-color: #47517D;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #fff;
}

.footericon {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.footerboxcompany {
  position: relative;
}

.linefootercompany {
  position: absolute;
  top: 0px;
  right: 0px;
}

.linefootercompany {
  position: absolute;
  top: 12px;
  right: 15px;
}

.linefootercompany2 {
  position: absolute;
  top: 12px;
  right: 78px;
}

.linefootercompany3 {
  position: absolute;
  top: 12px;
  right: 60px;
}

.mainsliderawared .slick-dots {
  position: absolute;
  bottom: -44px;
  display: block;
  width: 100%;
  padding: 0;
  display: flex;
  margin: 0;
  list-style: none;
  text-align: center;
}

.mainsliderawared .slick-dots li {
  position: relative;
  display: inline-block;
  width: 33%;
  height: 20px;
  margin: 0px;
  padding: 0;
  cursor: pointer;
}

.mainsliderawared .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 2px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.mainsliderawared .slick-dots li button::before {
  font-family: slick;
  font-size: 26px;
  line-height: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: rgb(255, 255, 255);
  content: "";
  text-align: center;
  opacity: 0.25;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
}

.mainsliderawared .slick-dots li.slick-active button:before {
  opacity: 1;
  color: rgb(230, 230, 230);
}

.bannersidebottel {
  position: absolute;
  left: -27px;
  top: 125px;
}


.businessanalyticsadd {
  position: absolute;
  left: -19px;
  bottom: 0px;
}

.analytics {
  position: relative;
}

.financecompresive {
  position: absolute;
  bottom: -14px;
  left: 11px;
}

.financecompresive img {
  width: 50%;
}

.parnetrimages {
  position: relative;
}

.parnetrimages img {

  z-index: 9;
}

/* -------------------------------------------about page start------------------------------------- */

.banner-about {
  background-image: url('../images/aboutusbanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
}

.bannervedio img {
  width: 100%;
}

.aboutbannercontent h1 {
  color: #273266;
  font-size: 34px;
  font-weight: 400;
  margin: 0px;
  line-height: 47px;
}

.aboutbannercontent h1 span {
  color: #5185F6;
}

.aboutbannercontent p {
  color: #273266;
  opacity: 0.50;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  padding: 30px 0px;
  line-height: 26px;
}

.aboutbanerrighttext p {
  color: #273266;
  font-size: 14px;
  margin: 0px;
  font-weight: 400;
}

.bestsuitedimg {
  margin-right: 15px;
}

.bestsuitedimg3 {
  margin-right: 15px;
}

.bestsuitedimg2 {
  margin-right: 15px;
}

.banner-about {
  padding: 60px 0px;
}

.whowearecontent h2 {
  color: #548AFF;
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
}

.whowearetitlle {
  position: relative;
}

.whoweareline {
  position: absolute;
  left: 120px;
  top: 9px;
}

.whowearecontent h3 {
  color: #273266;
  font-size: 16px;
  margin: 0px;
  font-weight: 400;
  line-height: 30px;
  padding: 15px 0px;
}

.whowearecontent p {
  color: #000000;
  font-size: 14px;
  margin: 0px;
  font-weight: 400;
  opacity: 0.50;
}

.whoweare {
  padding: 80px 0px;
}
.whoweareimagesleft img {
  width: 100%;
}

.whowearecontent {
  padding-left: 45px;
}

.ourmissiontittle h2 {
  color: #548AFF;
  font-size: 16px;
  margin: 0px;
  font-weight: 600;
}

.ourmissiontittle p {
  color: #273266;
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  padding-top: 7px;
}

.ourmissiontittle {
  text-align: center;
  padding-bottom: 40px;
}

.ourmission {
  background-color: #EFF4FF;
  padding: 75px 0px;
}

.bestsecuirityimg {
  margin-right: 20px;
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.ourmissionboxcontent {
  display: flex;
}

.ourmissionboxcontentsecuirity h4 {
  color: #273266;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.ourmissionboxcontentsecuirity p {
  color: #000000;
  opacity: 0.50;
  font-weight: 400;
  margin: 0px;
  padding-top: 5px;
  font-size: 12px;
}

.ourmissionboxcontent {
  border-right: 1px solid #7070704d;
  padding-right: 20px;
}

.newlastbox {
  border-right: none !important;
}








/* ------------------------------------------------service page start----------------------------------- */


.banner-service-content h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 500;
  margin: 0px;
  line-height: 50px;
}

.banner-service-content p {
  color: #FFFFFF;
  opacity: 0.69;
  font-weight: 400;
  margin: 0px;
  font-size: 14px;
  padding: 30px 0px;
  line-height: 28px;
}

.service-input input {
  background-color: #60688F;
  border: none;
  color: white;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  padding-left: 54px;
}

.service-input input::placeholder {
  color: #FFFFFF;
  opacity: 0.57;
  font-size: 14px;
}

.service-input input:focus-visible {
  outline: none !important;
}

.service-input {
  position: relative;
  width: 65%;
  height: 50px;
  margin: 0 auto;
}

.searchimages {
  position: absolute;
  top: 14px;
  left: 13px;
}

.searchimages img {
  width: 70%;
}

.banner-service {
  background-image: url('../images/servicebanner.png');
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
}

.newservicesearch {
  top: 5px;
  right: 8px;
  padding: 10px 20px;
  border-radius: 8px;
  border: inherit;
}

.provide-content h2 {
  color: #548AFF;
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
}

.provide-content p {
  color: #273266;
  font-weight: 400;
  font-size: 18px;
  margin: 0px;
  padding-top: 7px;
}

.provide-content {
  text-align: center;
  position: relative;
  padding-bottom: 30px;
}

.provideline {
  position: absolute;
  top: -25px;
  margin: 0px auto;
  left: 0px;
  right: 0px;
}

.provideboxcontent h4 {
  color: #273266;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
  padding: 20px 0px;
}

.provideboxcontent p {
  color: #000000;
  opacity: 0.50;
  font-weight: 400;
  margin: 0px;
  font-size: 12px;
}

.provideboxcontent img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.provideboxcontent {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 6px 0px #70707030;
}

.provideboxcontent:hover {
  border: 1px solid #cd243280;
  border-radius: 10px;
}

.provide {
  padding: 100px 0px;
}

.boxserviceprovide {
  margin-top: 30px;
}

.srevices-content {
  position: relative;
}

.testimonialassocaite {
  background-image: url('../images/testiparmet.png');
  background-position: center;
  background-size: cover;
  height: 660px;
  display: flex;
  align-items: center;
}

.associattestimonail {
  border-bottom: none;
  border-radius: 5px;
}

.newassociatimages {
  width: 11%;
  padding-bottom: 10px;
}

.parnerwithus img {
  width: 100%;
}

.parnerwithus {
  margin: 0px auto;
  display: flex;
  justify-content: center;
}

.mainsliderassociat .slick-initialized .slick-slide {
  display: block;
  padding: 20px !important;
}

.mainsliderassociat .slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 30px;
  content: '';
  text-align: center;
  opacity: .25;
  color: black;
  background-color: #548AFF;
  opacity: 0.27;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mainsliderassociat .slick-dots li.slick-active button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 27px;
  height: 7px;
  border-radius: 30px;
  content: '';
  text-align: center;
  opacity: .25;
  color: black;
  background-color: #548AFF;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mainsliderassociat .slick-dots li.slick-active {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  margin-right: 26px;
}

.mainsliderassociat .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.newtakeserviceadd {
  background-color: #548AFF;
  padding: 50px;
  border-radius: 20px;
}

.take-serviceform {
  background-color: #FFFFFF;
  border-radius: 18px;
  padding: 40px;
}

.takeserviceboxcontentquery h2 {
  color: #FFFFFF;
  font-size: 30px;
  margin: 0px;
  font-weight: 500;
  line-height: 40px;
}

.takeserviceboxcontentquery p {
  color: #FFFFFF;
  font-size: 12px;
  opacity: 0.71;
  margin: 0px;
  font-weight: 400;
  padding: 30px 0px;
  line-height: 23px;
}

.takeserviceboxcontentquery h3 {
  color: #FFFFFF;
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
  padding-bottom: 15px;
}

.take-service {
  padding: 60px 0px;
}

.forminputtakeservice input {
  background-color: #F1F1F1;
  width: 100%;
  height: 45px;
  border: inherit;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.forminputtakeservice input:focus-visible {
  outline: none;
}

.forminputtakeservice input::placeholder {
  color: #273266;
  opacity: 0.47;
  font-size: 14px;
  font-weight: 500;
}

.formtextmessage textarea {
  border: inherit;
  background: #F1F1F1;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  height: 130px;
}

.takeservicesubmitbtn {
  background-color: #273266 !important;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-color: #5185F6 !important;
  padding: 14px 185px;
  border: none !important;
  margin-top: 19px;
  width: 100%;
}

.takeservicesubmitbtn:focus {
  box-shadow: none !important;
  border: none;
}

.formtextmessage textarea:focus {
  outline: none !important;
}

.formtextmessage textarea::placeholder {
  color: #273266;
  opacity: 0.47;
  font-size: 14px;
  font-weight: 500;
}

.aboutlineus2 {
  position: absolute;
  top: -4px;
  left: 218px;
}




/* -------------------------------------------service-details page start---------------------------------- */

.banner-service-detilas {
  background-image: url('../images/servicedetails.png');
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
}

.guranted-solution-content h2 {
  color: #548AFF;
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
}

.guranted-solution-content h3 {
  color: #273266;
  font-size: 16px;
  margin: 0px;
  font-weight: 400;
  line-height: 30px;
  padding: 20px 0px;
}

.guranted-solution-content p {
  color: #000000;
  font-size: 13px;
  opacity: 0.82;
  margin: 0px;
  line-height: 24px;
  padding-bottom: 20px;
}

.guranted-solution-images img {
  width: 100%;
}

.guranted-solution {
  padding: 85px 0px;
}
.guranteedearnparagraph {
  color: #273266;
  font-size: 16px;
  margin: 0px;
  font-weight: 400;
  padding-left: 18px;
}

.guranteedbottomcontent {
  background-color: #548aff12;
  border-radius: 10px;
  padding: 25px;
  position: relative;
}

.guranteednouniamges {
  position: absolute;
  top: -11px;
}

.guranteednouniamges img {
  width: 70%;
}

.distinctio-images img {
  width: 100%;
}

.distinctio-contentadd h3 {
  color: #273266;
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
  line-height: 30px;
  padding: 20px 0px;
}

.distinctio-contentadd p {
  color: #000000;
  font-size: 13px;
  opacity: 0.82;
  margin: 0px;
  line-height: 24px;
  padding-bottom: 20px;
}

.distinctio-contentadd {
  padding-left: 60px;
}

.distinctio {
  padding: 40px 0px;
}

.newaccusamusadd {
  padding-left: 0px !important;
}

.newaccusamusaddimages {
  padding-left: 60px;
}

.advisers-iamges h2 {
  color: #273266;
  font-size: 22px;
  margin: 0px;
  font-weight: 600;
  line-height: 33px;
}

.advisers {
  background-color: #F9F9F9;
  padding: 60px 0px;
}

.advisers-iamges img {
  width: 54%;
}

.servicedetailsbody {
  padding: 0px;
}

.servicedetailsbody p {
  color: #000000;
  opacity: 0.82;
  font-size: 13px;
  font-weight: 400;
  margin: 0px;
  padding-bottom: 11px;
}

.newservicedetailsaccordianadd .accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: inherit;
  box-shadow: inherit;
  padding-bottom: 10px;
}

.newservicedetailsaccordianadd .accordion-item {
  background-color: inherit;
  border: none;
  border-bottom: 1px solid #7070705e;
  padding-bottom: 20px;
  padding-top: 20px;
}

.newservicedetailsaccordianadd .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px;
  font-size: 16px;
  color: #273266;
  text-align: left;
  background-color: inherit;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.newservicedetailsaccordianadd {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 10px 30px 10px 30px;
}

.newservicedetailsaccordianadd .accordion-button:not(.collapsed)::after {
  background-image: url("../images/noun-newicon.png");
  transform: rotate(-180deg);
}

.newservicedetailsaccordianadd .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("../images/noun-plus-2268151.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
}

.newservicedetailsaccordianadd .accordion-button:focus {
  outline: 0;
  outline: none;
  box-shadow: none;
}

.readyimg {
  position: absolute;
  right: 115px;
  top: -4px;
}

.readyimagescontent {
  position: relative;
}

.readyimg img {
  width: 50%;
}



/* -------------------------------------------achivement page start--------------------------------- */

.indiaexellenceimages img {
  width: 100%;
}

.india-exellence {
  padding-top: 60px;
}

.india-exellence-boxcontent span {
  color: #548AFF;
  font-size: 40px;
  font-weight: 600;
  opacity: 0.13;
}

.newexellenceadd {
  background-color: #F6F9FF;
  border-radius: 30px;
  padding: 50px;
}

.india-exellence-boxcontent h3 {
  color: #548AFF;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  padding-top: 7px;
}

.india-exellence-boxcontent p {
  color: #000000;
  font-size: 13px;
  opacity: 0.82;
  margin: 0px;
  font-weight: 400;
  padding-top: 20px;
}

.india-exellence-boxcontent {
  padding-left: 25px;
}

.newexellenceadd2 {
  background-color: #FDF6F7;
  border-radius: 30px;
  padding: 50px;
}

.boxcontentindia {
  padding-left: 0px;
}

.newachivmentadd {
  background-color: transparent !important;
  padding-top: 80px;
}

.ourmissiontittle {
  position: relative;
}

.your-exoerience {
  background-image: url('../images/experienceimg.png');
  background-position: center;
  background-size: cover;
  height: 300px;
  display: flex;
  align-items: center;
}

.your-exoerience-boxcontent h3 {
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 600;
  margin: 0px;
  padding: 10px 0px;
}

.your-exoerience-boxcontent p {
  margin: 0px;
  color: #FFFFFF;
  font-size: 13px;
  opacity: 0.62;
}

.your-exoerience-boxcontent {
  text-align: center;
}

.your-exoerience-boxcontent img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}



/* --------------------------------------------sidebar------------------------------------- */

.sidenav {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 99999;
  top: 0px;
  right: 0px;
  background-color: #EFF4FF;
  overflow-x: hidden;
  transition: all 0.5s ease 0s;
  padding-top: 60px;
}

.mainsidemenucloseedit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #548affd9;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 14px;
  border-radius: 50px;
}

.sidenav .nav-link.active {
  color: #548AFF;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #273266;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  top: -45px;
  right: 13px;
  font-size: 36px;
  margin-left: 0;
}

.maincontentsideneww {
  position: relative;
}








/* ------------------------------------------contact us page start---------------------------------------- */


.gettouch-withus-content h2 {
  color: #273266;
  font-size: 30px;
  margin: 0px;
  font-weight: 600;
}

.gettouch-withus-content p {
  color: #959595;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 5px;
}

.gettouch-withus-addres {
  color: #000000;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
  padding-top: 15px;
}

gettouch-withus-addres:hover {
  color: #000000;
}

.newgetusicon {
  color: #548AFF;
}

.young-womniamges img {
  width: 83%;
}

.gettouch-withus {
  padding: 60px 0px;
}

.newcontactformadd .form-label {
  color: #212121;
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
  padding-bottom: 5px;
}

.newcontactformadd input {
  width: 100%;
  border: 1px solid #70707040;
  border-radius: 5px;
  height: 50px;
}

.newcontactformadd input:focus-visible {
  outline: none;
  box-shadow: none;
}

.newcontactformadd input::placeholder {
  font-size: 14px;
  color: #000000;
  opacity: 0.38;
  font-weight: 500;
}

.newcontactformadd textarea::placeholder {
  font-size: 14px;
  color: #000000;
  opacity: 0.38;
  font-weight: 500;
}

.newconsentadd {
  color: #959595;
  font-size: 14px;
  margin: 0px;
  font-weight: 400;
  padding-top: 23px;
}

.contactussubmitbtnadd {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 210px;
  background-color: #273266;
  border-color: #273266;
  width: 100%;
}

.contactussubmitbtnadd:hover {
  color: #fff;
  background-color: #273266;
  border-color: #273266;
}

.submitingadd {
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.contactussubmitbtnadd:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.frequently-content {
  text-align: center;
  margin-bottom: 40px;
}

.frequently-content h2 {
  color: #273266;
  font-size: 21px;
  margin: 0px;
  font-weight: 600;
}

.frequently {
  padding-bottom: 60px;
}


.frequently .accordion-button:not(.collapsed) {
  color: #548AFF;
  font-size: 14px;
  font-weight: 500;
  background-color: #FFFFFF;
  padding: 0px ;
  box-shadow: none;
  border-bottom: 1px solid #70707038;
  padding-bottom: 16px;
}

.frequently .accordion-item {
  background-color: #FFFFFF;
  border: none;
}

.frequently .accordion-body p {
  color: #000000;
  opacity: 0.82;
  font-size: 13px;
  font-weight: 400;
  margin: 0px;
  padding-top: 20px;
}

.frequently .accordion-body {
  padding: 0px;
}

/* .frequently .accordion {
  padding: 20px 40px 20px 40px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px -2px #7070709e;
  border-radius: 10px;
} */

.newaccorsionfrequentlyadd{
  padding: 20px 40px 20px 40px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px -2px #7070709e;
  border-radius: 10px;
}
.frequently .accordion-button:focus-visible {
  outline: 0;
  box-shadow: none !important;
}

.frequently .accordion-button:not(.collapsed)::after {
  background-image: url("../images/noun-newicon.png");
  transform: rotate(-180deg);
}

.frequently .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("../images/noun-plus-2268151.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
}

.frequently .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px;
  font-size: 15px;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}
.credirpairimg {
  border-left: 4px solid #F2F3F6;
  opacity: 0.8;
  padding-right: 34px;
}
.credirpairimg{
position: relative;
}
.bindu {
  position: absolute;
  left: -19px;
  top: 36px;
}
.bindu2 {
  position: absolute;
  left: -19px;
  bottom: 73px;
}
.binduinputradio{
  position: absolute;
  left: 8px;
  top: 3px;
  width: 18px;
  height: 18px;
  border: inherit;
}
.binduinputradio2{
  position: absolute;
  left: 8px;
  top: 3px;
  width: 18px;
  height: 18px;
  border: inherit;
}
.binduinputradio:checked {
  background-color: #afbdca;
  border-color: inherit;
}
.binduinputradio2:checked {
  background-color: #78a2ff;
  border-color: inherit;
}
.binduinputradio:focus {
  border-color: inherit;
  outline: 0;
 box-shadow: inherit; 
}
.binduinputradio2:focus {
  border-color: inherit;
  outline: 0;
 box-shadow: inherit; 
}










@media(max-width: 1200px) {
  .newawardedadd {
      height: 492px;
  }

  .awardebytittle {
      top: 52px;
      left: 470px;
  }

  .newfootercontact {
      margin-top: 20px;
  }

  .banner-content h1 {
      font-size: 24px;
  }

  .banner-content p {
      font-size: 13px;
  }

  .aboutbusinesopurtonity {

      font-size: 16px;
      line-height: 26px;
  }

  .footerlogo {
      width: 26%;
      padding-bottom: 35px;
  }

  .newlastbox {
      margin-top: 20px;
  }

  .ourmissionboxcontent {
      border-right: none;
      padding-right: 0px;
  }

  .banner-about {
      height: auto;
  }

  .banner-about {
      padding: 40px 0px;
  }

  .banner-service-content h1 {
      font-size: 20px;
  }

  .banner-service-content p {
      font-size: 11px;
  }

  .newservicesearch {
      right: 8px;
  }

  .searchimages {
      left: 13px;
  }

  .banner-service {
      height: 350px;
  }

  .parnerwithus img {
      display: none;
  }

  .takeservicesubmitbtn {
      padding: 14px 140px;
  }

  .takeserviceboxcontentquery h2 {
      font-size: 22px;
      line-height: 30px;
  }

  .guranteedearnparagraph {
      font-size: 15px;
  }

  .advisers-iamges img {
      width: 67%;
  }

  .readyimg img {
      width: 50% !important;
  }

  .readyimg {
      position: absolute;
      right: 68px;
      top: -6px;
  }

  .newservicedetailsaccordianadd .accordion-button {
      font-size: 15px;
  }

 

  .contactussubmitbtnadd {
      padding: 10px 200px;
  }
}











@media(max-width: 991px) {
  .banner {
      height: 410px;
  }

  .banner-content h1 {
      font-size: 26px;
      line-height: 35px;
  }

  .banner-content p {
      font-size: 14px;
  }

  .abouts-usboxcontet p {
      font-size: 14px;
      line-height: 21px;
  }

  .aboutbusinesopurtonity {
      font-size: 14px;
  }

  .abourparagraphbeatea {
      font-size: 14px;
      padding-top: 14px;
  }

  .getintouchinput {
      justify-content: flex-start;
  }

  .getintouchinput input {
      width: 100%;
  }

  .analyticsboxcontent h3 {
      font-size: 27px;
  }

  .newawardedadd {
      height: 370px;
  }

  .awardebytittle {
      top: 27px;
      left: 374px;
  }

  .mainsliderawared.slick-initialized .slick-slide {
      display: block;
      padding-top: 66px;
  }

  .awardebox h5 {
      font-size: 14px;
  }

  .mainsliderawared .slick-dots {
      bottom: -31px;
  }

  .newfootercontact {
      margin-top: 20px;
  }

  .linefootercompany3 {
      right: 8px;
  }

  .aboutbannercontent h1 {
      font-size: 21px;
  }

  .aboutbannercontent p {
      font-size: 12px;
      padding: 10px 0px;
  }

  .aboutbanerrighttext p {
      font-size: 12px;
  }

  .bestsuitedimg3 {
      margin-right: 6px;
  }

  .bestsuitedimg2 {
      margin-right: 6px;
  }

  .bestsuitedimg {
      margin-right: 6px;
  }

  .banner-about {
      height: 370px;
  }

  .whowearecontent h3 {
      font-size: 13px;
      line-height: 23px;
      padding: 10px 0px;
  }

  .whowearecontent p {
      font-size: 12px;
  }

  .bestsecuirityimg {
      margin-right: 10px;
  }

  .ourmissionboxcontent {
      border-right: none;
      padding-right: 0px;
  }

  .newlastbox {
      margin-top: 20px;
  }

  .takeservicesubmitbtn {
      padding: 10px 80px;
  }

  .take-serviceform {
      padding: 20px;
  }

  .banner-service-content h1 {
      font-size: 20px;
      line-height: 35px;
  }

  .banner-service-content p {
      font-size: 11px;

  }

  .newservicesearch {
      right: 8px;
  }

  .searchimages {
      left: 13px;
  }

  .banner-service {
      height: 350px;
  }

  .provideboxcontent {
      height: 290px;
  }

  .testimonialassocaite {
      height: 608px;
  }

  .parnerwithus img {
      display: none;
  }

  .guranteedearnparagraph {
      font-size: 12px;
  }

  .distinctio-contentadd {
      padding-left: 0px;
  }

  .distinctio-contentadd h3 {
      font-size: 14px;
      line-height: 25px;
      padding: 10px 0px;
  }

  .distinctio-contentadd p {
      font-size: 12px;
      line-height: 24px;
      padding-bottom: 10px;
  }

  .newaccusamusaddimages {
      padding-left: 0px;
  }

  .distinctio {
      padding: 14px 0px;
  }

  .advisers-iamges img {
      width: 100%;
  }

  .readyimg img {
      width: 50% !important;
  }

  .readyimg {
      position: absolute;
      right: -50px;
      top: -8px;
  }

  .your-exoerience-boxcontent h3 {
      font-size: 28px;
  }

  .contactussubmitbtnadd {
      padding: 10px 143px;
  }

 

  .frequently .accordion {
      padding: 20px 20px 20px 20px;
  }
}

















@media(max-width: 767px) {

  .banner {
      height: 350px;
  }

  .banner-content h1 {
      font-size: 21px;
      line-height: 30px;
  }

  .newlearenmorebtn {
      font-size: 14px;
  }

  .banner-content p {
      font-size: 13px;
      padding: 16px 0px;
  }

  .abouts-usboxcontet p {
      font-size: 12px;
      line-height: 21px;
  }

  .aboutcontentright {
      margin-top: 40px;
  }

  .aboutbusinesopurtonity {
      font-size: 14px;
  }

  .abourparagraphbeatea {
      font-size: 13px;
      padding-top: 16px;
  }

  .srevices-content p {
      font-size: 13px;
      padding-top: 10px;
      line-height: 23px;
  }

  .partnetcontent {
      padding-bottom: 14px;
  }

  .assocaitepartner .nav {
      justify-content: inherit;
      margin-bottom: 35px;
      display: flex;
      overflow: scroll;
      white-space: nowrap;
      flex-wrap: nowrap;
  }

  .parnetrimages img {
      width: 100%;
  }
  .newpartneroffring {
     padding-right: 20px;
      padding-left: 20px;
  }
  .getintouchinput input::placeholder {
      font-size: 14px;
  }
  .parnerboxcontent {
      margin-top: 20px;
  }

  .parnerboxcontent p {

      margin-top: 10px;
  }

  .analyticscontent {
      padding-bottom: 20px;
      text-align: center;
  }

  .analyticsboxcontent {
      padding-bottom: 20px;
  }

  .getintouchinput {
      justify-content: flex-start;
      margin-top: 20px;
  }

  .getintouchinput input {
      width: 100%;
  }

  .footerboxcompany {
      position: relative;
      margin-top: 20px;
  }

  .headernavitems {
      margin: 0px 0px 0px 0px;
  }

  .newawardedadd {
      height: 365px;
  }

  .awardebytittle {
      top: 29px;
      left: 196px;
      font-size: 16px;
  }

  .mainsliderawared.slick-initialized .slick-slide {
      display: block;
      padding-top: 71px;
  }

  .mainsliderawared .slick-dots li {
      width: 58%;
  }

  .partnetcontent h2 {
      font-size: 14px;
      line-height: 25px;
  }

  .analyticsboxcontent h3 {
      font-size: 23px;
  }

  .awardebox h5 {
      font-size: 13px;
  }

  .banner-about {
      height: auto;
  }

  .banner-about {
      padding: 35px 0px;
  }

  .aboutbannercontent h1 {
      font-size: 21px;
      line-height: 30px;
  }

  .aboutbannercontent p {
      font-size: 13px;
      padding: 15px 0px;
  }

  .aboutbanerrighttext p {
      font-size: 12px;
  }

  .bannervedio {
      margin-bottom: 25px;
  }

  .whowearecontent {
      padding-left: 0px;
      margin-top: 25px;
  }

  .whowearecontent h3 {
      font-size: 13px;
      line-height: 24px;
      padding: 10px 0px;
  }

  .ourmissionboxcontent {
      border-right: none;
      padding-right: 0px;
      margin-bottom: 20px;
  }

  .newlastbox {
      margin-bottom: 0px;
  }

  .newtakeserviceadd {
      padding: 20px;
  }

  .takeservicesubmitbtn {
      padding: 7px 90px;
      margin-top: 5px;
  }

  .take-serviceform {
      padding: 20px;
  }

  .takeserviceboxcontentquery h2 {
      font-size: 20px;
      line-height: 24px;
  }

  .banner-service {
      height: auto;
      padding: 30px 0px;
  }

  .banner-service-content h1 {
      font-size: 15px;
      line-height: 30px;
  }

  .banner-service-content p {
      font-size: 11px;
      padding: 20px 0px;
  }

  .newservicesearch {
      right: 10px;
  }

  .service-input input {
      width: 100%;
      height: 50px;
      padding-left: 26px;
  }

  .searchimages {
      position: absolute;
      top: 14px;
      left: 0px;
  }

  .provide-content p {
      font-size: 13px;
  }

  .provideboxcontent {
      margin-bottom: 25px;
  }

  .boxserviceprovide {
      margin-top: 0px;
  }

  .testimonialassocaite {
      height: auto;
      padding: 40px 0px;
  }

  .srevices-content {
      padding-bottom: 25px;
  }

  .provide {
      padding-bottom: 30px;
  }

  .guranted-solution-content h3 {
      font-size: 14px;
      line-height: 25px;
      padding: 15px 0px;
  }

  .guranted-solution-images {
      margin-bottom: 30px;
  }

  .guranteedearnparagraph {
      font-size: 13px;
      padding-left: 0px;
  }

  .distinctio {
      padding: 0px 0px;
  }

  .distinctio-contentadd {
      padding-left: 0px;
  }

  .distinctio-contentadd h3 {
      font-size: 15px;
      line-height: 24px;
      padding: 20px 0px;
  }

  .newaccusamusaddimages {
      padding-left: 0px;
  }

  .advisers-iamges img {
      width: 100%;
  }

  .readyimg {
      right: 0px;
  }

  .newservicedetailsaccordianadd {
      margin-top: 30px;
  }

  .newexellenceadd {
      padding: 20px;
  }

  .india-exellence {
      padding: 30px 0px;
  }

  .india-exellence-boxcontent {
      padding-left: 0px;
      padding-top: 20px;
  }

  .newexellenceadd2 {
      padding: 20px;
  }

  .your-exoerience {
      height: auto;
      padding: 20px 0px;
  }

  .your-exoerience-boxcontent {
      padding-bottom: 20px;
  }

  .footerlogo {
      width: 49%;
      padding-bottom: 22px;
  }

  .indiabg {
      margin-bottom: 30px;
  }

  .indiabg1 {
      margin-top: 30px;
  }

  .newformad {
      margin-top: 20px;
  }

  

  .typebox {
      margin-top: 10px;
  }

  .contactussubmitbtnadd {
      padding: 10px 124px;
  }

  .frequently .accordion-button {
      padding: 10px;
      font-size: 13px;
  }

  .frequently .accordion {
      padding: 20px 20px 20px 20px;
  }
  .credirpairimg {
      display: none;
  }
  .aboutlineus2 {
     display: none;
  }
  .service-input {
      width: 100%;
  }
  .banner-service-detilas {
      background-position: inherit;
      height: 300px;
  }
}

.footernavcontentadd li p.footertextwhite {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 400;
  opacity: 0.83;
  text-decoration: none;
  margin-bottom: 0;
}


.newslider .slick-dots .slick-active div
{
  width: 30px !important;
  color: white !important;
  border: 1px solid #0000ff00 !important;
  font-size: 13px !important;
}

.newslider .slick-dots li div
{
  width: 30px !important;
  color: #273266 !important;
  border: 1px solid #0000ff00 !important;
  font-size: 13px !important;
}

.newslider .slick-dots
{
  background-color: #dddddd00 !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.serviceboxcontent  .mainserviceboxtext{
  font-size: 13px;
  color: #0000008c;
  font-weight: 400;
  opacity: 1;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  height: 60px;
}
.testimonialtextdot
{
  margin: 0px;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 15px;
  line-height: 23px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden;
 height: 138px;
 margin-bottom: 10px;
}
.testimonialtextdot span
{
  margin: 0px;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 400;
  padding-bottom: 15px;
  line-height: 23px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden;
 height: 138px;
 margin-bottom: 10px;
}


.provideboxcontent p {
  color: #000000;
  opacity: 0.50;
  font-weight: 400;
  margin: 0px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
 height: 72px;
}